<template>
  <div class="work_detail">
    <div class="user_info">
      <div class="user_info_content" v-if="detail.author">
        <p class="user_info_content_name">
          {{ detail.author }}
          <span class="user_info_content_name_desc">{{ detail.authorTitle }}</span>
        </p>
        <p class="user_info_content_hospital">{{ detail.authorHospital }}</p>
      </div>

      <div class="user_info_content" v-else>
        <p class="user_info_content_name">医阵营</p>
      </div>
    </div>
    <h2 class="title">{{ detail.name }}</h2>

    <div class="label">
      <p v-for="(item, index) in detail.labelList" :key="`label-${index}`" class="tab">
        <img class="tab__icon" src="@/img/icon-tag.png" alt="">
        <span>{{ item.label }}</span>
      </p>
    </div>

    <!-- 文章创作组件 -->
    <div class="work_detail_content" v-if="detail.type == 1">
      <div v-html="detail.typeContent"></div>
    </div>
    <!-- 录课作品详情 -->
    <div v-if="detail.type == 2">
      <video style="width: 100%;" :src="detail.typeVideo" :poster="detail.coverUrl" controls></video>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    detail: {},
    TimerBuriedPointPlaying: null
  }),
  mounted() {
    const openid = window.localStorage.getItem('openid')
    if (openid) {
      // 设置用户上报数据
      this.$buriedPoint.setUserInfo({
        user_unique_id: openid,
        user_id: openid,
        user_type: 0
      })
    }
    this.getDetail()
  },
  destroyed() {
    this.deactivated()
  },
  computed: {
    getOpenid() {
      return window.localStorage.getItem('openid')
    }
  },
  methods: {
    async getDetail() {
      const res = await this.$api.work.inforDetail({ id: this.$route.params.id })
      if (!res.code) {
        this.detail = res.data
        document.title = res.data.name

        if (this.getOpenid) {
          // 进入页面上报数据1
          this.$buriedPoint.push({
            event_type: 'entry_page',
            params: {
              live_id: this.detail.recordId || this.detail.id,
              live_name: this.detail.title,
              live_type: { 1: 'luk', 2: 'creation' }[this.detail.type]
            }
          })
          this.startBuriedPoint()
        }
      }
    },

    deactivated() {
      if (this.getOpenid) {
        // 离开页面上报数据1
        this.$buriedPoint.push({
          event_type: 'leave_page',
          params: {
            live_id: this.detail.recordId || this.detail.id,
            live_name: this.detail.title,
            live_type: { 1: 'luk', 2: 'creation' }[this.detail.type]
          }
        })
        this.$buriedPoint.send()
      }
    },

    // 上报定时器回调
    startBuriedPointPushPlaying() {
      const openid = window.localStorage.getItem('openid')
      if (openid) {
        this.$buriedPoint.push({
          event_type: 'playing',
          params: {
            live_id: this.detail.recordId || this.detail.id,
            live_name: this.detail.title,
            live_type: { 1: 'luk', 2: 'creation' }[this.detail.type]
          }
        })
      }

    },

    // 开始上报
    startBuriedPoint() {
      this.startBuriedPointPushPlaying()
      this.TimerBuriedPointPlaying = setInterval(this.startBuriedPointPushPlaying, 20 * 1000)
    },
  }

}
</script>

<style scoped lang="less">
  .user_info {
    &_content{
      padding-left: 0;
      &_name {
        font-weight: 400;
      }
    }
  }
</style>

<style lang="less">
  .work_detail_content {
    img,
    table {
      max-width: 100%;
    }
  }
</style>